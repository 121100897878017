import { composeEventHandlers } from '../../../utils/compose-event-handlers'
import { Modal } from '../Modal'
import { useModalContext } from '../../../contexts/modal-context'
import * as React from 'react'

export type RootProps = {
  id: string
  onOpenChange?(open: boolean, props?: unknown): void
} & Omit<Modal.RootProps, 'onOpenChange'>

export const Root = (props: RootProps) => {
  const {
    children,
    id,
    title,
    onOpenChange,
    defaultOpen,
    className,
    ...restProps
  } = props
  const { activeModalId, activeModalProps, closeModal, openModal } =
    useModalContext()

  const open = React.useMemo(() => activeModalId === id, [activeModalId, id])

  React.useEffect(() => {
    if (defaultOpen) openModal(id)
  }, [])

  React.useEffect(() => {
    onOpenChange?.(open, activeModalProps)
  }, [open, onOpenChange, activeModalProps])

  return (
    <Modal.Root
      title={title}
      onOpenChange={(open) => (open ? openModal(id) : closeModal())}
      open={open}
      className={className}
      {...restProps}
    >
      {children}
    </Modal.Root>
  )
}

export const CloseButton = (props: Modal.CloseButtonProps) => {
  const { onClick, ...restProps } = props

  const { closeModal } = useModalContext()

  return (
    <Modal.CloseButton
      onClick={composeEventHandlers(onClick, closeModal)}
      {...restProps}
    />
  )
}
